import React from "react";
import SkillBtn from "../components/SkillBtn";
import data from "../constants/skills.json";
import { motion } from "framer-motion";
import Gradient from "../components/Gradient";
let cardVariants = {};
const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
if (!isMobile) {
  cardVariants = {
    offscreen: { opacity: 0, scale: 0.5 },
    onscreen: { opacity: 1, scale: 1 },
  };
}
export default function Skills() {
  return (
    <div className="Skills section">
      {/* <Gradient></Gradient> */}
      <div className="section-title-wrapper d-flex align-items-center">
        <div className="section-title">skills</div>
        <div className="line"></div>
      </div>
      <div className="skill-section">
        {Object.keys(data).map((key, index) => {
          return (
            <div key={index} className="skill-type-wrapper">
              <div className="type">{key} :</div>
              <div className="skills-wrapper d-flex flex-wrap">
                {data[key].map((skill, index) => {
                  return (
                    <motion.div
                    variants={cardVariants}
                      initial="offscreen"
                      whileInView="onscreen"
                      transition={{
                        duration: 0.8,
                        delay: 0.1 * index,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                      key={index}
                    >
                      <SkillBtn data={skill}></SkillBtn>
                    </motion.div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
