import "./App.scss";
import "./Typography.scss";
import Header from "./components/Header";
import About from "./sections/About";
import Hero from "./sections/Hero";
import Skills from "./sections/Skills";
import Works from "./sections/Works";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <div className="container">
        <Hero></Hero>
        <Works></Works>
        <Skills></Skills>
        <About></About>
      </div>
    </div>
  );
}

export default App;
