import React from "react";
import { motion } from "framer-motion";

let cardVariants = {};
const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
if (!isMobile) {
  cardVariants = {
    offscreen: {
      y: 100,
      zIndex: 1,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      zIndex: 1,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1.8,
      },
    },
  };
}

function Card({ data }) {
  return (
    <div className="Card">
      <div className="outer-section d-flex align-items-end w-100">
        {/* <div className="year">{data.year}</div> */}
        <motion.div
          className="card-container"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={cardVariants}
        >
          <div
            className={`image-container ${
              data.platform === "mobile" && "image-container--mobile"
            }`}
          >
            <img src={require(`../assets/images/${data.mockup}.png`)} alt="" />
          </div>
        </motion.div>
      </div>
      <div className="inner-section">
        <div className="name">{data.name}</div>
        <div className="company">{data.company}</div>
        <div className="description heading-5">{data.description}</div>
        <div className="tech">
          {data.tech.map((item) => {
            return <span key={item} className="small-text">{item} .</span>;
          })}
        </div>
      </div>
    </div>
  );
}

export default Card;
