import React from "react";
import head from "../assets/images/head.png";
import { useInView, motion } from "framer-motion";
// import { useInView } from "react-intersection-observer";
import { useRef } from "react";
import Gradient from "../components/Gradient";

let textVariant = {};
const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
if (!isMobile) {
  textVariant = {
    offscreen: { opacity: 0},
    onscreen: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  }
}

function Hero() {
  const heroRef = useRef(null);
  const isInView = useInView(heroRef);
  return (
    <div ref={heroRef} className="Hero section d-flex align-items-center">
      {/* <Gradient></Gradient> */}
      <div className="hero-row align-items-center d-flex flex-wrap">
        <div className="hero-row__text col">
          <motion.div
          variants={textVariant}
            initial='offscreen'
            whileInView='onscreen'
          >
            <div className="sub-heading">Hi there, im</div>
          </motion.div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="70"
            viewBox="0 0 529.024 70.09"
            className={isInView ? "active" : ""}
          >
            <path
              id="benjamin"
              d="M46.354-19.208c0,7.448-5.292,11.858-14.112,11.858H16.464V-31.458H31.948C40.67-31.458,46.354-26.754,46.354-19.208ZM44.59-49.882c0,6.958-4.8,11.074-13.132,11.074H16.464V-60.956h14.8C39.984-60.956,44.59-56.644,44.59-49.882ZM55.272-18.326c0-8.428-6.076-15.582-13.524-16.856C48.314-37.338,53.7-42.336,53.7-50.96c0-9.506-7.252-17.346-21.658-17.346H7.546V0h25.48C47.138,0,55.272-8.036,55.272-18.326ZM114.17-68.4H77.42V0h36.75V-7.35H86.338V-30.87H111.23v-7.35H86.338V-61.054H114.17Zm68.11,0v54.292L146.412-68.4h-8.918V0h8.918V-54.39L182.28,0H191.2V-68.4Zm59.29,50.568c0,5.684-2.842,10.584-9.9,10.584s-10-5-10.094-11.27H212.66C212.66-6.566,220.3.686,231.672.686c11.27,0,18.914-7.154,18.914-18.522v-50.47H241.57ZM323.792,0H333.2L308.6-67.914h-10.29L273.616,0h9.408l5.488-15.19H318.3Zm-8.036-22.442h-24.7l12.348-34.5ZM353.78,0H362.7V-50.666L385.336,0h6.272l22.54-50.568V0h8.918V-67.816H413.56L388.472-11.76,363.384-67.816h-9.6Zm94.178,0h8.918V-68.306h-8.918Zm78.694-68.4v54.292L490.784-68.4h-8.918V0h8.918V-54.39L526.652,0h8.918V-68.4Z"
              transform="translate(-7.046 68.904)"
              fill="none"
              stroke="#242132"
              strokeWidth="2"
            ></path>
          </svg>
          <motion.div
          variants={textVariant}
            initial='offscreen'
            whileInView='onscreen'
          >
            <p className="paragraph">
              <span>designer</span>
              <span className="dot">.</span>
              <span>developer</span>
            </p>
          </motion.div>
          <motion.div
          variants={textVariant}
            initial='offscreen'
            whileInView='onscreen'
          >
            <h2 className="heading-4">
              An experienced developer with a keen eye for aesthetic design,
              crafting visually captivating user interfaces for web and mobile
              applications
            </h2>
          </motion.div>
        </div>
        <div className="hero-row__img col d-flex justify-content-center">
          <img src={head} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
