import SocialIcons from "./SocialIcons";
import logo from "../assets/images/logo.png";
import hamburger from "../assets/images/menu.png";

function Header() {
  return (
    <div className="Header">
      <div className="Header__menu container d-flex justify-content-center">
        <div className="Header__logo">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Header;
