import behance from "../assets/images/behance.svg";
import instagram from "../assets/images/instagram.svg";
import linkedin from "../assets/images/linkedin.svg";

function SocialIcons() {
  return (
    <div className="SocialIcons">
      <a
        href="https://www.behance.net/nibinbenjamin"
        target="_blank"
        rel="noreferrer"
      >
        <img src={behance} alt="" />
      </a>
      <a
        href="https://www.instagram.com/one.mr.ben"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagram} alt="" />
      </a>
      <a
        href="https://www.linkedin.com/in/nibin-benjamin"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedin} alt="" />
      </a>
    </div>
  );
}

export default SocialIcons;
