import React from "react";

function SkillBtn({ data }) {
  return (
    <div className="SkillBtn d-flex justify-content-center align-items-center flex-column">
      <img src={require(`../assets/images/skills/${data.img}.png`)} alt="" />
      <div>{data.name}</div>
    </div>
  );
}

export default SkillBtn;
