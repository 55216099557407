import Card from "../components/Card";
import OwlCarousel from "react-owl-carousel";
import Data from "../constants/works.json";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Gradient from "../components/Gradient";

export default function Works() {
  return (
    <div className="Works section">
      {/* <Gradient></Gradient> */}
      <div className="section-title-wrapper d-flex align-items-center">
        <div className="section-title">recent works</div>
        <div className="line"></div>
      </div>
      <div className="work-cards d-flex h-100">
        <OwlCarousel
          className="owl-theme work-carousel"
          margin={100}
          responsive={{
            0: {
              items: 1,
            },
            768: {
              items: 2,
            },
            1200: {
              items: 2,
            },
          }}
          dots={false}
          nav={true}
          navText={[
            "<div class='nav-button owl-prev'>‹</div>",
            "<div class='nav-button owl-next'>›</div>",
          ]}
          lazyLoad={true}
        >
          {Data.works.map((work) => {
            return <Card key={work.mockup} data={work}></Card>;
          })}
        </OwlCarousel>
      </div>
    </div>
  );
}
