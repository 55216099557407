import React from "react";
import Gradient from "../components/Gradient";
import ScrollText from "../components/ScrollText/ScrollText";
import SocialIcons from "../components/SocialIcons";

export default function About() {
  return (
    <div className="About section d-flex flex-column">
      {/* <Gradient></Gradient> */}
      <div className="section-title-wrapper d-flex align-items-center">
        <div className="section-title">about</div>
        <div className="line"></div>
      </div>
      <div className="about-section d-flex flex-column align-items-center justify-content-center text-center">
        <div className="mb-5 description">
          My inbox is always open. Whether you have a question or just want to
          say hi, I’ll try my best to get back to you!{" "}
        </div>
        <ScrollText></ScrollText>
        <a href="mailto: benjaminnibin@gmail.com" className="mb-5 email">
          benjaminnibin@gmail.com
        </a>
        <SocialIcons></SocialIcons>
        <div className="Footer">
        <button className="mb-5">
         Designed in Adobe XD and coded in Visual Studio Code by <span className="Footer__highlight" onClick={() => window.scrollTo(0,0)}>ben</span>. Built with React.js , deployed with Firebase. 
        </button>
        </div>
      </div>
    </div>
  );
}
